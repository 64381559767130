<template>
  <div>
    <!-- dialog -->
    <!-- dialog -->
    <!-- top -->
    <van-sticky>
      <van-nav-bar
        title=""
        @click-left="$router.push('/selfData')"
        left-text="返回"
        left-arrow
      >
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <van-cell-group style="margin-top: 15px" inset>
      <van-cell to="/bankCardP" title="收款账户" is-link value="设定" />
      <van-cell to="/bankCardC" title="提现账户" is-link value="设定" />
    </van-cell-group>
    <!-- <foot /> -->
  </div>
</template>
<script>
// import foot from "../../components/footer";
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped></style>
